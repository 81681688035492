import React from 'react'

import './jobGroup.scss'

const JobGroup = ({ children }) => (
  <div className="job-group">
    <span className="job-group-title">Open Roles</span>
    <div className="job-group-jobs">
      {children}
    </div>
  </div>
)

export default JobGroup
