import React from 'react'

import './job.scss'

const Job = ({ link, title, location }) => (
  <div className="job">
    <a href={link} className="title">{title}</a>
    <a className="location">{location}</a>
  </div>
)

export default Job
