import React, { Component } from 'react'

 import './articleGroup.scss'

 class ArticleGroup extends Component {
  render() {
    const { children } = this.props

     return (
      <div className="article-group">
        <span className="article-group-title">Articles</span>
        <div className="article-group-articles">
          {children}
        </div>
      </div>
    )
  }
}

 export default ArticleGroup
