import React from "react"
import SEO from '../components/seo'
import Navigation from '../components/navigation/navigation'
import ArticleGroup from '../components/articleGroup/articleGroup'
import Article from '../components/article/article'
import JobGroup from '../components/jobGroup/jobGroup'
import Job from '../components/job/job'
import Footer from '../components/footer/footer'

import './index.scss'

import mastheadimg from "../images/home/triangle.svg" // Tell Webpack this JS file uses this image
import article1 from "../images/articles/design-at-plangrid.png" // Tell Webpack this JS file uses this image
import article2 from "../images/articles/researcher-journey.png" // Tell Webpack this JS file uses this image
import article3 from "../images/articles/hiring-a-user-researcher.png" // Tell Webpack this JS file uses this image
import article4 from "../images/articles/construction-site.png" // Tell Webpack this JS file uses this image
import article5 from "../images/articles/hire-blueprint.png" // Tell Webpack this JS file uses this image
import article6 from "../images/articles/welcome-mat.png" // Tell Webpack this JS file uses this image
import article7 from "../images/articles/dark-art-of-design.png" // Tell Webpack this JS file uses this image
import article8 from "../images/articles/manager-hero-trap.png" // Tell Webpack this JS file uses this image
import article9 from "../images/articles/design-system-brick.png" // Tell Webpack this JS file uses this image

const IndexPage = ({ data }) => (
  <Navigation>
    <SEO title="PlanGrid Design" keywords={[`plangrid`, `design`, `plangrid design`]} />

    <div className="mastehead">
      <div className="content">
        <h2>Shaping the future of construction</h2>
        <p>Our mission is to craft frictionless, high
        quality experiences that transform how construction teams build.</p>
      </div>
      <div className="image">
        <img src={mastheadimg} alt="Logo" />
      </div>
    </div>
    <JobGroup>
      <Job
        title="Product Designer"
        location="San Francisco, CA"
        link="https://jobs.lever.co/plangrid/ecbad80f-d20d-4533-b90a-af24611816f2"
      />
      <Job
        title="Design Manager"
        location="San Francisco, CA"
        link="https://jobs.lever.co/plangrid/81e6f43e-c488-463f-90cc-fba689103589"
      />
      <Job
        title="User Researcher"
        location="San Francisco, CA"
        link="https://jobs.lever.co/plangrid/1f3c9507-370f-4216-b7b2-efd7bb522ab2"
      />
    </JobGroup>
    <ArticleGroup>
      <Article
        title="Building a design system one brick at a time"
        category="Design"
        desc="At PlanGrid, the key to creating a successful design system was to move in small steps that demonstrated value and motivated institutional change."
        date="4/16/19"
        author="Alissa Briggs"
        link="https://medium.com/plangrid-design/building-a-design-system-one-brick-at-a-time-a6233b173742"
        imageUrl={article9}
      />
      <Article
        title="Why We Visit Construction Sites"
        category="Research"
        desc="On January 3, 2011, I delivered the greatest usability study ever conducted."
        date="10/16/18"
        author="Peter"
        link="https://medium.com/plangrid-design/why-we-visit-construction-sites-a2aad8afee31"
        imageUrl={article4}
      />
      <Article
        title="Design at PlanGrid"
        category="Design"
        desc="Choosing where to invest your skills and energy is an important decision."
        date="10/16/18"
        author="Alissa Briggs"
        link="https://medium.com/plangrid-technology/design-at-plangrid-450f1cf53ec7"
        imageUrl={article1}
      />
      <Article
        title="Strategy: hiring a user researcher"
        category="Research"
        desc="Building a strong team is the most important thing a research manager can do."
        date="06/11/18"
        author="Dave Hora"
        link="https://medium.com/@stonecrops/strategy-hiring-a-user-researcher-95c78f0e7b13"
        imageUrl={article2}
      />
      <Article
        title="The researcher’s journey: leveling up as a user researcher"
        category="Research"
        desc="On January 3, 2011, I delivered the greatest usability study ever conducted."
        date="10/16/18"
        author="Dave Hora"
        link="https://medium.com/@stonecrops/the-researchers-journey-leveling-up-as-a-user-researcher-a85cd35b53f5"
        imageUrl={article3}
      />
      <Article
        title="Rolling out the welcome mat for your new designer"
        category="Design"
        desc="Design Managers: set your new hire up for success."
        date="03/30/17"
        author="Alissa Briggs"
        link="https://medium.com/plangrid-design/rolling-out-the-welcome-mat-for-your-new-designer-a41825e97429"
        imageUrl={article6}
      />
      <Article
        title="The New Hire Blueprint: how to succeed at your new design job"
        category="Design"
        desc="You’ve got three months to make an impression. Here’s how."
        date="01/30/17"
        author="Alissa Briggs"
        link="https://medium.com/plangrid-design/the-new-hire-blueprint-how-to-succeed-at-your-new-design-job-46f42156eec8"
        imageUrl={article5}
      />
      <Article
        title="The not-so-dark art of design management"
        category="Design"
        desc="As a young designer, I thought managers were like Gandalf:"
        date="10/12/16"
        author="Alissa Briggs"
        link="https://medium.com/plangrid-design/the-not-so-dark-art-of-design-management-80ce2ff9cdd9"
        imageUrl={article7}
      />
      <Article
        title="Escaping the Manager-Hero Trap"
        category="Design"
        desc="How to help your team design their own solutions"
        date="09/15/15"
        author="Alissa Briggs"
        link="https://medium.com/plangrid-design/escaping-the-manager-hero-trap-bcb13a33e8e9"
        imageUrl={article8}
      />
    </ArticleGroup>
    <Footer />
  </Navigation>
)

export default IndexPage
