import React from 'react'

import './article.scss'

const Article = ({ author, category, date, desc, imageUrl, link, title }) => (
  <div className="article">
    <a href={link} target="_blank">
      <div className="article-image-container">
        <div
          className="article-image"
          style={{
            color: 'red',
            backgroundImage: `url(${imageUrl})`
          }}
        />
      </div>
      <div className="article-category">{category}</div>
      <span className="article-title">{title}</span>
      <span className="article-description">{desc}</span>
      <div className="article-meta">
        <div>{date}   |   {author}</div>
      </div>
    </a>
  </div>
)

export default Article
